<template>
	<panel
		ref="panelRef"
		:show="show"
		class="inboundReport-section"
		@close="close"
	>
		<template #title>
			Inbound Reports
		</template>
		<template>
			<div v-if="showReportForm">
				<div class="inbound-report__title-icon">
					<InboundReportIcon />
				</div>
				<div
					v-if="isDropdownOpen || isReportTypeDropdownOpen"
					class="panel-bg"
					@click="handleClick"
				></div>
				<div>
					<div class="form-group">
						<div class="dropdown-container">
							<label class="dropdown-label">
								Report Type
							</label>
							<div
								class="dropdown-header"
								@click="toggleReportTypeDropdown"
							>
								<span>{{
									reportTypeName || 'Select Report Type'
								}}</span>
								<DropdownIcon
									:is-open="isReportTypeDropdownOpen"
								/>
							</div>
							<div
								v-if="isReportTypeDropdownOpen"
								class="dropdown-list"
							>
								<div
									v-for="option in reportOptions"
									:key="option.value"
									class="dropdown-item"
									@click="
										selectReportType(
											option.value,
											option.label
										)
									"
								>
									{{ option.label }}
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label class="form-label form-group-title-new">
							Included Partners ({{ maxPartnerLimit }} max)
						</label>

						<div class="dropdown-container">
							<div
								class="dropdown-header"
								@click="toggleDropdown"
							>
								<span>{{
									selectedPartners.length > 0
										? `${selectedPartners.length} Partner Selected`
										: 'Select Partners'
								}}</span>
								<DropdownIcon :is-open="isDropdownOpen" />
							</div>

							<div v-if="isDropdownOpen" class="dropdown-list">
								<label
									v-if="
										linkedPartners.length > 1 &&
											linkedPartners.length <
												this.maxPartnerLimit
									"
									class="custom-checkbox select-checkbox-label"
								>
									<input
										id="select-all"
										v-model="selectAll"
										type="checkbox"
										@change="toggleSelectAll"
									/>
									<label for="select-all">
										<span class="checkmark">
											<CheckIcon />
										</span>
										Select All</label
									>
								</label>
								<div
									class="scrollable-list"
									:style="{
										maxHeight:
											this.panelHeight * 0.5 + 'px',
									}"
								>
									<div
										v-for="partner in linkedPartners"
										:key="partner.id"
										class="custom-checkbox"
									>
										<input
											:id="`partner-${partner.id}`"
											v-model="selectedPartners"
											type="checkbox"
											:value="partner"
											:disabled="
												isCheckboxDisabled(partner)
											"
											@change="checkLimit"
										/>
										<label :for="`partner-${partner.id}`"
											><span class="checkmark">
												<CheckIcon /> </span
											>{{ partner.name }}
											<span
												v-if="
													selectedPartners.includes(
														partner
													)
												"
											>
											</span>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label class="form-label form-group-title-new">
							Start Date ({{ reportDateRange }} days max)
						</label>
						<div class="date-picker-container" data-wrap>
							<flat-pickr
								id="start"
								ref="startPicker"
								v-model="startDate"
								:config="configs.start"
								type="text"
								class="form-input"
								placeholder="Start Date"
							/>
							<span
								class="calendar-icon"
								@click.prevent="openStartPicker"
							>
								<CalenderIcon />
							</span>
						</div>
					</div>
					<div class="form-group">
						<label class="form-label form-group-title-new">
							End Date
						</label>
						<div class="date-picker-container" data-wrap>
							<flat-pickr
								id="end"
								ref="endPicker"
								v-model="endDate"
								:config="configs.end"
								type="text"
								class="form-input"
								placeholder="End Date"
							/>
							<span
								class="calendar-icon"
								@click.prevent="endEndPicker"
							>
								<CalenderIcon />
							</span>
						</div>
					</div>
					<div class="form-group form-button">
						<button
							class="action-button"
							:disabled="!isDateValid || isButtonDisabled"
							@click="runReport"
						>
							Run Report
						</button>
					</div>
				</div>
			</div>

			<div v-if="showGeneratedReport">
				<div class="inboundReport-list">
					<div
						v-for="report in reportData"
						:key="report.id"
						class="inboundReport-list_item"
					>
						<div class="inboundReport-list_item-content">
							<div>{{ report.name }}</div>
							<div>
								{{ formatDate(report.parameters.start_date) }}
								to
								{{ formatDate(report.parameters.end_date) }}
							</div>
						</div>
						<div
							v-if="report.status !== 'failed'"
							class="inboundReport-list_item-icon"
						>
							<div>
								<!-- Download Button -->
								<a :href="report.output_url" download>
									<DownloadIcon />
								</a>
							</div>
							<div>Download</div>
						</div>
						<div v-else class="inboundReport-list_item-icon">
							<div class="inboundReport-list_item-icon-failed">
								Failed
							</div>
						</div>
					</div>
				</div>
				<div class="form-group form-button">
					<button
						class="action-button"
						@click="clearAndGenerateReport"
					>
						Clear and Generate <br />New Report
					</button>
				</div>
			</div>
			<div v-if="showLoader" class="loader-section">
				<div
					v-if="Array.isArray(reportData) && reportData.length === 0"
				>
					<div class="loader-section__container">
						<div class="loader-section__container__title">
							Loading...
						</div>
						<div>
							<loader :threshold="0.65" :enabled="true">
								<font-awesome-icon
									class="ml-2"
									:icon="['far', 'circle-notch']"
									spin
								/>
							</loader>
						</div>
					</div>
				</div>
				<div v-else>
					<div class="loader-section__container">
						<div class="loader-section__container__title">
							Creating Report
						</div>
						<div>
							<loader :threshold="0.65" :enabled="true">
								<font-awesome-icon
									class="ml-2"
									:icon="['far', 'circle-notch']"
									spin
								/>
							</loader>
						</div>
						<div class="loader-section__container__message">
							Do not leave this page until completed
						</div>
						<div class="form-group form-button">
							<button
								class="action-button"
								@click="clearAndGenerateReport"
							>
								Clear and Generate <br />New Report
							</button>
						</div>
					</div>
				</div>
			</div>
		</template>
	</panel>
</template>

<script>
import Panel from '@/components/Panels/Panel.vue'
import PanelMixin from '@/mixins/PanelMixin'
import { mapGetters } from 'vuex'
import 'flatpickr/dist/flatpickr.css'
import FlatPickr from 'vue-flatpickr-component'
import Loader from '@/components/Loader.vue'
import DropdownIcon from '@/components/Icons/DropdownIcon.vue'
import InboundReportIcon from '@/components/Icons/InboundReportIcon.vue'
import CalenderIcon from '@/components/Icons/CalenderIcon.vue'
import DownloadIcon from '@/components/Icons/DownloadIcon.vue'
import CheckIcon from '@/components/Icons/CheckIcon.vue'
import moment from 'moment'

/**
 * Constant representing the timestamp format the API uses.
 *
 * @type {String}
 */
const API_FORMAT = 'ddd, DD MMM YYYY HH:mm:ss [GMT]'

// import { mapActions } from 'vuex'

export default {
	components: {
		CheckIcon,
		DownloadIcon,
		InboundReportIcon,
		CalenderIcon,
		DropdownIcon,
		Loader,
		Panel,
		FlatPickr,
	},

	computed: {
		...mapGetters('partners', {
			getLinkedPartners: 'getLinkedPartners',
			partner: 'active',
		}),

		isDateValid() {
			return !this.dateError
		},

		/**
		 * Get the linked partners and the current partner, sorted alphabetically.
		 *
		 * @return {Array}
		 */
		linkedPartners() {
			if (!this.partner) return []

			const activePartner = {
				id: this.partner.id,
				name: this.partner.name,
			}

			// Get linked partners and sort them by name
			const linkedPartners = this.getLinkedPartners(
				this.partner.id
			).sort((a, b) => a.name.localeCompare(b.name))

			const isActivePartnerIncluded = linkedPartners.some(
				partner => partner.id === activePartner.id
			)
			return isActivePartnerIncluded
				? linkedPartners
				: [activePartner, ...linkedPartners].sort((a, b) =>
						a.name.localeCompare(b.name)
				  )
		},

		/**
		 * Retrieves the partner ID from the current route's parameters and converts it to an integer.
		 */
		partnerId() {
			return parseInt(this.$route.params.partner)
		},
	},

	methods: {
		handleClick() {
			this.isReportTypeDropdownOpen = false
			this.isDropdownOpen = false
		},
		// Convert ISO date to local timezone and format as per user's locale this from api response
		formatDate(isoDate) {
			if (!isoDate) return ''
			const date = new Date(isoDate)
			return date.toLocaleDateString('en-US', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			})
		},

		getPanelHeight() {
			this.$nextTick(() => {
				if (this.$refs.panelRef) {
					const panelElement = this.$refs.panelRef.$el // Access the root DOM element of the Panel
					this.panelHeight = panelElement.offsetHeight // Get and store the height
				}
			})
		},

		//handles open of start date
		openStartPicker() {
			this.isReportTypeDropdownOpen = false
			this.isDropdownOpen = false
			const flatPicker = this.$refs.startPicker.fp

			if (!flatPicker.isOpen) {
				flatPicker.open()
				flatPicker.close()
				return flatPicker.open()
			}

			flatPicker.close()
		},

		//handles open of end date
		endEndPicker() {
			this.isReportTypeDropdownOpen = false
			this.isDropdownOpen = false
			const flatPicker = this.$refs.endPicker.fp

			if (!flatPicker.isOpen) {
				flatPicker.open()
				flatPicker.close()
				return flatPicker.open()
			}

			flatPicker.close()
		},

		//to get compoent data
		async fetchReportData() {
			if (this.partnerId && this.show) {
				const partnerId = this.partnerId
				const apiUrl = `${this.baseUrl}/api/v4/reports/partner/${partnerId}`

				try {
					this.isLoading = true

					const response = await fetch(apiUrl, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${this.$store.state.auth.session.access_token}`,
						},
					})

					if (!response.ok) {
						this.$alert.error(
							'Failed to fetch report data: Please try again'
						)
						this.showReportForm = true
						this.showLoader = false
						this.clearForm()
						return
					}

					const data = await response.json()

					//condtional handling for pending and other senraios
					if (Array.isArray(data) && data.length === 0) {
						this.showReportForm = true
						this.showGeneratedReport = false
						this.showLoader = false
					} else if (
						Array.isArray(data) &&
						data.some(report => report.status === 'pending')
					) {
						this.showLoader = true
						this.showReportForm = false
						this.showGeneratedReport = false
					} else {
						this.showGeneratedReport = true
						this.showReportForm = false
						this.showLoader = false
					}

					this.reportData = data
				} catch (error) {
					this.$alert.error('Error fetching report data')
					this.reportData = null
					this.showReportForm = true
					this.showGeneratedReport = false
					this.showLoader = false
				} finally {
					this.isLoading = false
				}
			} else {
				this.showReportForm = false
				this.showGeneratedReport = false
				this.showLoader = false
			}
		},

		// Method to run the report (API call) called on run report
		async runReport() {
			this.isReportTypeDropdownOpen = false
			this.isDropdownOpen = false
			// handling validations
			if (!this.reportType) {
				this.$alert.error('Please select a report type')
				return
			}

			if (this.selectedPartners.length === 0) {
				this.$alert.error(
					'Please include partners for report generation'
				)
				return
			}

			if (!this.reportType) {
				this.$alert.error('Please select a report type')
				return
			}
			// Validate start and end date first
			if (!this.startDate || !this.endDate) {
				this.$alert.error('Please select a valid date range')
				return
			}

			// Ensure date range is valid
			if (!this.isDateValid) {
				this.$alert.error('Invalid date range')
				return
			}

			// Disable the button to prevent multiple clicks
			this.isButtonDisabled = true

			// Set a timeout to enable the button again after 10 seconds
			setTimeout(() => {
				this.isButtonDisabled = false
			}, 10000) // 10 seconds

			// Format start and end date as per API requirements
			const formattedStartDate = moment(this.startDate)
				.startOf('day') // Set time to 00:00:00
				.utc()
				.format(API_FORMAT)

			const formattedEndDate = moment(this.endDate)
				.endOf('day') // Set time to 23:59:59
				.utc()
				.format(API_FORMAT)
			const payload = {
				name:
					this.reportType === 'v1'
						? 'Inbound Call V1 Report'
						: 'Inbound Call V2 Report',
				type: `inbound-calls-${this.reportType}`,
				genereted_by_partner: this.partnerId,
				parameters: {
					start_date: formattedStartDate,
					end_date: formattedEndDate,
					partner_ids: this.selectedPartners.map(partner =>
						partner.id.toString()
					),
					...(this.reportType === 'v2' && { include_activity: '1' }),
				},
			}
			const apiUrl = `${this.baseUrl}/api/v4/reports/report`

			try {
				const response = await fetch(apiUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${this.$store.state.auth.session.access_token}`,
					},
					body: JSON.stringify(payload),
				})

				if (!response.ok) {
					// Extract and throw the error message from the response
					const errorResponse = await response.json()
					throw new Error(
						errorResponse.message || 'Failed to generate report'
					)
				}

				const data = await response.json()
				this.$alert.success('Report generation is in progress')
				this.fetchReportData()
				this.reportData = data

				// Handle auto-refresh by polling the API
				const pollInterval = setInterval(async () => {
					if (!this.showLoader) {
						clearInterval(pollInterval)
						return
					}
					await this.fetchReportData()
				}, 10000)
			} catch (error) {
				this.$alert.error(
					error.message ||
						'An unexpected error occurred while generating the report.'
				)
			} finally {
				this.isButtonDisabled = false // Re-enable the button
			}
		},

		async clearAndGenerateReport() {
			try {
				const partnerId = this.partnerId
				const apiUrl = `${this.baseUrl}/api/v4/reports/partner/${partnerId}/clear`
				const response = await fetch(apiUrl, {
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${this.$store.state.auth.session.access_token}`,
					},
				})

				if (!response.ok) {
					throw new Error('Failed to clear and generate new report')
				}

				this.$alert.success('Reports cleared successfully.')

				//manually restting
				this.showGeneratedReport = false
				this.reportData = []

				this.fetchReportData()
				//Clearing form after reports generated.
				this.reportType = ''
				this.reportTypeName = ''
				this.selectedPartners = []
				this.startDate = ''
				this.endDate = ''
			} catch (error) {
				this.$alert.error('Error clearing report')
			}
		},

		toggleDropdown() {
			this.showDropdown = !this.showDropdown
			this.isDropdownOpen = !this.isDropdownOpen
			this.isReportTypeDropdownOpen = false
		},

		toggleSelectAll() {
			if (this.selectAll) {
				this.selectedPartners = [...this.linkedPartners]
			} else {
				this.selectedPartners = []
			}
		},
		checkLimit() {
			if (this.selectedPartners.length > this.maxPartnerLimit) {
				this.isDropdownOpen = false
				this.$alert.error(
					`You can select a maximum of ${this.maxPartnerLimit} partners.`
				)
				this.selectedPartners.pop()
			}
		},
		isCheckboxDisabled(partner) {
			// Disable if the limit is reached and the partner is not already selected
			return (
				this.selectedPartners.length > this.maxPartnerLimit &&
				!this.selectedPartners.includes(partner)
			)
		},
		toggleReportTypeDropdown() {
			this.isReportTypeDropdownOpen = !this.isReportTypeDropdownOpen
			this.isDropdownOpen = false
		},

		selectReportType(value, label) {
			this.reportType = value
			this.reportTypeName = label
			this.isReportTypeDropdownOpen = false
		},

		clearForm() {
			;(this.selectedPartners = []),
				(this.startDate = ''),
				(this.endDate = ''),
				(this.reportType = ''),
				(this.reportTypeName = ''),
				(this.isReportTypeDropdownOpen = false),
				(this.isDropdownOpen = false)
		},
	},
	/**
	 * The component's mergeable mixins.
	 *
	 * @type {Array}
	 */
	mixins: [PanelMixin],

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'InboundReports',

	watch: {
		// Watch for changes to 'show' and call API when it's true
		show(newVal) {
			if (newVal) {
				this.fetchReportData() // Initial fetch when show is true
				this.getPanelHeight()

				// Call the API every 30 seconds for auto refersh
				this.pollIntervalShow = setInterval(() => {
					if (this.show) {
						this.fetchReportData()
					} else {
						clearInterval(this.pollIntervalShow) // Stop polling if show becomes false
					}
				}, 30000) // 30 seconds
			} else {
				clearInterval(this.pollIntervalShow) // Stop polling when show is false
				//Clearing form after show is set as false.
				this.clearForm()
			}
		},

		// Watch for changes to 'startdate'
		startDate(newVal) {
			if (newVal) {
				const minDate = new Date(newVal)
				this.configs.end.minDate = minDate
				const maxDate = new Date(newVal)
				maxDate.setDate(maxDate.getDate() + (this.reportDateRange - 1))
				this.configs.end.maxDate = maxDate
				const maxAllowedDate = new Date() // Ensure max date doesn't exceed today
				maxAllowedDate.setDate(maxAllowedDate.getDate())
				this.configs.end.maxDate =
					maxDate > maxAllowedDate ? maxAllowedDate : maxDate
				if (!this.endDate) {
					this.endDate = null
				}
				if (new Date(this.endDate) > this.configs.end.maxDate) {
					this.endDate = this.configs.end.maxDate
				}
			}
		},

		// If Partner is switched reports form should refersh
		'partner.id'(newPartnerId, oldPartnerId) {
			if (newPartnerId !== oldPartnerId) {
				this.clearForm()
			}
		},
	},

	mounted() {
		this.fetchReportData()
	},

	data() {
		return {
			panelHeight: 0, // Store the dynamically calculated panel height
			isClosingPicker: false,
			maxPartnerLimit: 15,
			reportDateRange: 100,
			isReportTypeDropdownOpen: false,
			selectedReportType: '',
			reportOptions: [
				{ value: 'v1', label: 'Inbound call VI' },
				{ value: 'v2', label: 'Inbound call V2' },
			],
			configs: {
				start: {
					allowInput: false,
					altInput: true,
					altFormat: 'm/d/Y',
					dateFormat: 'n/j/Y',
					maxDate: new Date(),
					wrap: true,
				},
				end: {
					allowInput: false,
					altInput: true,
					altFormat: 'm/d/Y',
					dateFormat: 'n/j/Y',
					wrap: true,
					minDate: null,
					maxDate: new Date(new Date().setDate(new Date().getDate())),
				},
			},
			show: true,
			showReportForm: false,
			showGeneratedReport: false,
			reportType: '',
			reportTypeName: '',
			includedPartner: '',
			selectedPartners: [],
			selectedItems: [],
			selectAll: false,
			isDropdownOpen: false,
			showDropdown: false,
			startDate: '',
			endDate: '',
			reportData: [],
			baseUrl: process.env.VUE_APP_API_URL,
			showLoader: true,
			pollIntervalShow: null, //polls intervals on component open state
			isButtonDisabled: false, //disables the run eport button
		}
	},
}
</script>
